import { resolveId } from '@wix/communities-blog-client-common';
import { FETCH_RELATED_POSTS_SUCCESS } from '../../../post-page/actions/fetch-related-posts';
import { FETCH_SEARCH_SUCCESS } from '../../../search/actions/fetch-search-types';
import { FETCH_ARCHIVE_SUCCESS } from '../../actions/fetch-archive';
import { FETCH_CATEGORY_POSTS_SUCCESS } from '../../actions/fetch-category-posts';
import { FETCH_FEED_POSTS_SUCCESS } from '../../actions/fetch-feed-posts';
import { FETCH_HASHTAG_POSTS_SUCCESS } from '../../actions/fetch-hashtag-posts';
import { FETCH_POST_METADATA_SUCCESS } from '../../actions/fetch-post-metadata';
import { FETCH_TAG_POSTS_SUCCESS } from '../../actions/fetch-tag-posts';
import { SET_POSTS } from '../../actions/set-posts';
import { UPDATE_POST_COUNTERS } from '../post-counters/post-counters-actions';
import {
  INCREMENT_POST_LIKE_COUNT_REQUEST,
  INCREMENT_POST_LIKE_COUNT_SUCCESS,
  INCREMENT_POST_LIKE_COUNT_FAILURE,
} from './post-likes-actions-types';

export default function postLikes(state = {}, { type, payload }) {
  switch (type) {
    case FETCH_POST_METADATA_SUCCESS:
      return post(state, payload);
    case FETCH_FEED_POSTS_SUCCESS:
    case SET_POSTS:
    case FETCH_RELATED_POSTS_SUCCESS:
    case FETCH_CATEGORY_POSTS_SUCCESS:
    case FETCH_TAG_POSTS_SUCCESS:
    case FETCH_ARCHIVE_SUCCESS:
    case FETCH_HASHTAG_POSTS_SUCCESS:
      return posts(state, payload);
    case FETCH_SEARCH_SUCCESS:
      return posts(state, payload.posts);
    case INCREMENT_POST_LIKE_COUNT_REQUEST:
    case INCREMENT_POST_LIKE_COUNT_SUCCESS:
    case INCREMENT_POST_LIKE_COUNT_FAILURE:
      return like(state, payload);
    case UPDATE_POST_COUNTERS:
      return postCounters(state, payload);
    default:
      return state;
  }
}

function post(state, metadata) {
  const { postPageMetadata, relatedPostsMetadata } = metadata;
  const posts = [postPageMetadata, ...relatedPostsMetadata];

  const postsById = posts.reduce((result, post) => {
    if (post.id in result) {
      return result;
    }

    result[post.id] = post.postMetadata;

    return result;
  }, {});

  return { ...state, ...postsById };
}

function posts(state, posts) {
  return {
    ...state,
    ...posts.reduce((result, post) => {
      result[resolveId(post)] = getLikes(post);
      return result;
    }, {}),
  };
}

function postCounters(state, payload) {
  const id = resolveId(payload);
  const post = state[id];
  const likeCount = payload.likeCount;

  if (!post || post.likeCount === likeCount) {
    return state;
  }

  return { ...state, [id]: { ...post, likeCount } };
}

function like(state, payload = {}) {
  const id = payload.id;
  const post = state[id];
  if (!post) {
    return state;
  }

  return { ...state, [id]: { ...payload } };
}

function getLikes(post) {
  return {
    liked: post.isLiked,
    likeCount: post.likeCount,
  };
}
