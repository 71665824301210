import { BLOG_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { createPerformanceTracker, resetMetrics, urijs } from '@wix/communities-blog-client-common';
import { type Router, setRouterMatch } from '../router';
import createRequest from '../services/create-request';
import getEnvironment from '../services/get-environment';
import { getHttpClientFromFlowAPI } from '../services/get-http-client-from-flow-api';
import { validateLanguage } from '../services/validate-language';
import { addDebugState, addErrorState } from '../store/debug-state/debug-state-actions';
import { parseInstance } from '../store/instance-values/parse-instance';
import { getPathname } from '../store/location/get-pathname';
import { type FlowAPI } from '../types';
import { type PlatformApi } from './platform-api';

const REGEX_POST_PAGE_PREVIEW = /[0-9a-fA-F]+\/preview\/([^/]+)\/?/;

export const getPreviewInstance = (location: PlatformApi['location']) => {
  const match = location.path.join('/').match(REGEX_POST_PAGE_PREVIEW);
  return match ? match[1] : undefined;
};

export const getInstance = (platformApi: PlatformApi) => () =>
  platformApi.site.getAppToken?.(BLOG_APP_ID);

export const resolveUser = (platformApi: PlatformApi, allowPreviewInstance?: boolean) => {
  const user = platformApi.user.currentUser;
  const isSeo = platformApi.seo.isInSEO();
  const previewInstance =
    allowPreviewInstance && !isSeo && getPreviewInstance(platformApi.location);
  if (previewInstance) {
    user.loggedIn = true;
    user.instance = previewInstance;
    user.id = parseInstance(previewInstance).uid;
  } else {
    user.instance = getInstance(platformApi)();
  }
  return user;
};

const noop = () => {};

export const createLogger = (isDebug?: boolean, isProduction?: boolean) =>
  !isProduction || isDebug ? console.log : noop;

export const onLocationChange = ({
  platformApi,
  callback,
}: {
  platformApi: PlatformApi;
  callback: (args: { path: string[] }) => void;
}) => {
  if (!platformApi.location.onChange) {
    return;
  }
  let currentPathname = platformApi.location.path.join('/');

  platformApi.location.onChange((args) => {
    const pathname = args.path.join('/');
    if (pathname === currentPathname) {
      return;
    }
    currentPathname = pathname;
    callback(args);
  });
};

export const doRouting = ({
  router,
  pathname,
  store,
  isInitialLoad = true,
}: {
  router: Router;
  pathname: string;
  store: any;
  isInitialLoad?: boolean;
}) =>
  isInitialLoad
    ? router.resolve(pathname).then((match) => store.dispatch(setRouterMatch(match)))
    : router.match(pathname);

export const createRequestWithBaseUrl =
  ({
    platformApi,
    getStore,
    bundleName,
    flowAPI,
  }: {
    platformApi: PlatformApi;
    getStore: () => any;
    bundleName: string;
    flowAPI: FlowAPI;
  }) =>
  (baseUrl: string) => {
    const { isDebug, isSSR } = getEnvironment(platformApi);
    return createRequest({
      baseUrl,
      getInstance: getInstance(platformApi),
      locale: validateLanguage(platformApi.site.language ?? ''),
      trackError: getStore ? (error) => getStore().dispatch(addErrorState(error)) : undefined,
      logResponse:
        isDebug && getStore
          ? (response) => getStore().dispatch(addDebugState(response))
          : undefined,
      petriOvr: platformApi.location.query.petri_ovr,
      siteRevision: platformApi.site.revision,
      performanceTracker: createPerformanceTracker(bundleName, {
        isDebug,
        isSSR,
      }) as any,
      httpClient: getHttpClientFromFlowAPI(flowAPI),
      isSSR,
    });
  };

export const appendOriginInBackend = ({
  platformApi,
  baseUrl,
}: {
  platformApi: PlatformApi;
  baseUrl: string;
}) => {
  const origin = urijs(platformApi.location.baseUrl).origin();
  const isBackend = platformApi.window.rendering.env === 'backend';
  return isBackend ? `${origin}${baseUrl}` : baseUrl;
};

export const createControllerId = () => new Date().getTime();

export const getSectionPathname = ({
  platformApi,
  sectionUrl,
}: {
  platformApi: PlatformApi;
  sectionUrl: string | undefined;
}) => {
  return getPathname(platformApi.location, sectionUrl);
};

export const handleLocationChange = ({
  platformApi,
  store,
  log,
  bundleName,
  router,
  sectionUrl,
}: {
  platformApi: PlatformApi;
  store: any;
  log: any;
  bundleName: string;
  router: any;
  sectionUrl: string | undefined;
}) => {
  onLocationChange({
    platformApi,
    callback: ({ path }) => {
      log('navigated to new path', path);
      resetMetrics(bundleName);
      doRouting({
        store,
        router,
        pathname: getSectionPathname({ platformApi, sectionUrl }),
        isInitialLoad: false,
      });
    },
  });
};
